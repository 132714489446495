<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ProductService from '@/services/product'
import defaultStoreImage from '../../../assets/images/default-store.png'
import { VMoney } from 'v-money'

export default {
  page: {
    title: "Produto",
  },
  components: { Layout, PageHeader },
  directives: { money: VMoney },
  data() {
    return {
      title: "",
      product: {},
      productData: {},
      productId: null,
      products: [],
      categories: [],
      brands: [],
      features: [],
      tags: [],
      defaultStoreImage,
      imageToRemove: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailHeight: 100,
        addRemoveLinks: true,
        destroyDropzone: true,
        createImageThumbnails: false
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      variation: {},
      variationTypes: [
        { code: 'size', description: 'Tamanho' },
        { code: 'flavor', description: 'Sabor' },
      ],
      productsVariation: [],
      fields: [
        { key: 'image', sortable: false, label: 'Produto' },
        { key: 'barcode', sortable: true, label: 'Cód. Barras' },
        { key: 'description', sortable: true, label: 'Descrição' },
        { key: 'variationDescription', sortable: true, label: 'Variação', formatter: (value, key, item) => {
          if (item.variationDescription) {
            const variationType = item.variationType === 'flavor' ? 'Sabor' : 'Tamanho';

            return `${variationType}: ${value}`;
          }

          return '';
        } },
        { key: 'brandDescription', sortable: true, label: 'Marca' },
        { key: 'categoryDescription', sortable: true, label: 'Categoria' },
        { key: 'actions', label: 'Ações' },
      ],
      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,
    }
  },
  methods: {
    async loadProduct() {
      const result = await ProductService.getStoreProduct(this.productId);

      this.product = result.data;
      this.productOriginal = this.product.product;

      this.formatProduct();
    },

    formatProduct() {
      this.product.price = this.product.price.toFixed(2).replace('.', ',');
    },

    saveProduct() {
      if (!this.productData._id) {
        return this.$toast.warning('É necessário selecionar o produto');
      }

      if (!this.product.code) {
        return this.$toast.warning('O código SKU é obrigatório');
      }

      if (!this.product.price) {
        return this.$toast.warning('O código SKU é obrigatório');
      }

      this.product.product = this.productData._id;

      let loader = this.$loading.show();

      if (!this.productId) {
        ProductService.createStoreProduct(this.product).then(res => {
          this.product._id = res.data._id;
          this.productId = res.data._id;
          this.$toast.open('Produto cadastrado com sucesso');

          loader.hide();
        }).catch((err) => {
          loader.hide();

          const errorData = err.response.data;
          if (errorData.code) {
            if (errorData.code === 'E9999') {
              return this.$toast.warning(errorData.msg);
            }
          }

          this.$toast.error('Ocorreu um erro ao gravar o produto');
        })
      } else {
        ProductService.updateStoreProduct(this.productId, this.product).then(() => {
          this.$toast.open('Produto atualizado com sucesso');
          loader.hide();
        }).catch((err) => {
          loader.hide();

          const errorData = err.response.data;
          if (errorData.code) {
            if (errorData.code === 'E9999') {
              return this.$toast.warning(errorData.msg);
            }
          }

          this.$toast.error('Ocorreu um erro ao gravar o produto');
        })
      }
    },

    findProduct() {
      this.products = [];
      this.searchValue = '';

      this.$bvModal.show('modal-find-product');
    },
    
    loadProducts(page = 1, term = '') {
      ProductService.getAllProductsToStore(page, term, 5)
        .then(res => {
          this.products = res.data.products;
          this.count = res.data.count;
          this.currentCount = res.data.currentCount;
          this.currentPage = res.data.currentPage;
          this.totalPages = res.data.totalPages;
          this.perPage = res.data.perPage;
          this.startIndex = res.data.startIndex;
          this.endIndex = res.data.endIndex;

          this.$forceUpdate();
        })
      .catch(() => {
      })
      
      this.totalRows = this.products.length;
    },
    onPageChange() {
      this.loadProducts(this.currentPage, this.searchValue);
    },
    search() {
      this.loadProducts(1, this.searchValue);
    },
    selectProduct(item) {
      this.productData = item;
      this.productData.price = this.productData.price.toFixed(2).replace('.', ',');

      this.$bvModal.hide('modal-find-product');
    }
  },
  mounted() {
    this.productId = this.$route.params.id;

    const requests = [];

    if (this.productId) {
      this.title = 'Editando Produto';
      requests.push(ProductService.getProductStore(this.productId));

      let loader = this.$loading.show();

      ProductService.getProductStore(this.productId).then(res => {
        loader.hide()

        this.product = res.data;
        this.productData = this.product.product;
        this.productData.price = this.productData.price.toFixed(2).replace('.', ',');

        this.formatProduct();
      }).catch(() => {
        loader.hide();
      })
    } else {
      this.title = 'Novo Produto';
    }
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="row mt-4 mb-4">
                <div class="col-md-3 text-center">
                  <img
                    alt="200x200"
                    :src="`${productData.image || defaultStoreImage}`"
                    data-holder-rendered="true"
                  />
                </div>

                <div class="col-md-9">
                  <div class="row mb-4">
                    <div class="col">
                      <button type="button" class="btn btn-primary" @click="findProduct">
                        <i class="mdi mdi-file-search-outline mr-1"></i> Localizar Produto
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="name">Código de Barras/EAN</label>
                        <input id="barcode" name="barcode" type="text" class="form-control" disabled v-model="productData.barcode" />
                      </div>
                    </div>
                
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="manufacturername">Descrição</label>
                        <input id="description" name="description" type="text" class="form-control" disabled v-model="productData.fullDescription" />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="manufacturerbrand">Marca</label>
                        <input id="email" name="email" type="email" class="form-control" disabled v-model="productData.brandDescription" />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="district">Cód. SKU</label>
                        <input id="district" name="district" type="text" class="form-control" v-model="product.code" />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="state">Estoque</label>
                        <input
                          id="state"
                          name="state"
                          type="state"
                          class="form-control"
                          v-model="product.stock"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="city">Preço</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">R$</span>
                          </div>
                          
                          <input
                            id="city"
                            name="city"
                            type="text"
                            class="form-control"
                            v-model="product.price"
                            v-money="money"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="city">Preço Original</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span id="validationTooltipUsernamePrepend" class="input-group-text">R$</span>
                          </div>
                          
                          <input
                            id="originalPrice"
                            name="originalPrice"
                            type="tel"
                            class="form-control"
                            v-model="productData.price"
                            v-money="money"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="productBarcode">Código de Barras</label>
                    <input id="productBarcode" name="productBarcode" type="text" class="form-control" v-model="product.barcode" />
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="productDescription">Nome do Produto</label>
                    <input id="productDescription" name="productDescription" type="text" class="form-control" v-model="product.description" />
                  </div>
                </div>

                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="productDescription">Marca</label>
                    <input id="productDescription" name="productDescription" type="text" class="form-control" v-model="product.description" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-sm-6">
        <router-link
          tag="a"
          to="/store/products"
          class="btn text-muted d-none d-sm-inline-block btn-link"
        >
          <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de produtos
        </router-link>
      </div>
      <!-- end col -->
      <div class="col-sm-6">
        <div class="text-sm-right">
          <!-- <router-link tag="a" to="/ecommerce/checkout" class="btn btn-success">
            Salvar e ir para Imagens
          </router-link> -->

          <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="saveProduct">
            <i class="mdi mdi-plus mr-1"></i> Salvar Produto
          </button>
        </div>
      </div>
      <!-- end col -->
    </div>

    <b-modal
      id="modal-find-product"
      title="Busca de Produto"
      title-class="font-18"
      centered
      size="xl"
    >
      <div class="row mb-2">
        <div class="col-12">
          <div class="position-relative search-input search-box">
            <form @submit.prevent="search" class="align-items-center">
              <input type="text" class="form-control" v-model="searchValue" placeholder="Pesquisar por código ou descrição..." />

              <button type="submit" class="btn btn-rounded btn-outline-primary ml-2">
                Pesquisar
              </button>
            </form>
          </div>
        </div>
      </div>

      <div class="table-responsive mb-0 align-middle">
        <b-table
          align="center"
          show-empty
          emptyText="Nenhum produto para exibir"
          :items="products"
          :fields="fields"
          responsive="sm"
          head-variant="light"
        >
          <template v-slot:cell(image)="row">
            <img
              v-bind:src="row.item.image"
              alt="product-img"
              title="product-img"
              class="avatar-sm"
            />
          </template>

          <template v-slot:cell(actions)="row" :class="{ 'row-disabled': row.item.hasProductStore}">
            <b-button variant="primary" class="btn btn-rounded" size="sm" @click="selectProduct(row.item)" :disabled="row.item.hasProductStore">Selecionar</b-button>
          </template>
        </b-table>
      </div>

      <div class="row justify-content-md-between align-items-md-center">
        <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
        <!-- end col-->
        <div class="col-xl-5">
          <div class="text-md-right float-xl-right mt-2 pagination-rounded">
            <b-pagination
              v-model="currentPage" 
              :total-rows="count"
              :per-page="perPage"
              @input="onPageChange"
            ></b-pagination>
          </div>
        </div>
        <!-- end col-->
      </div>
      
      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-find-product')">Fechar</b-btn>
      </div>
    </b-modal>
    
  </Layout>
</template>

<style lang="scss">
  .images {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .image {
      display: flex;
      flex-direction: row;
      align-items: center;

      .image-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .row-disabled {
    color: #bbb;
  }

  img {
      max-height: 200px;
      max-width: 200px;
    }

  .form-control:disabled {
    background-color: #e6e6e6;
  }

  .search-box form {
    display: flex;
    flex-direction: row;
  }

  .search-box .form-control {
    padding-left: 20px;
  }

  .input-search {
    width: 90%;
  }
</style>