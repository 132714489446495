import api from '@/services/api'

export default {
  getAllProducts(page, params, perPage = null) {
    let queryParams = `?page=${page}&term=${params.term}`;
    if (perPage) {
      queryParams += `&perPage=${perPage}`;
    }

    if (params.brands) {
      queryParams += `&brands=${params.brands.join(';')}`
    }

    if (params.categories) {
      queryParams += `&categories=${params.categories.join(';')}`
    }

    if (params.features) {
      queryParams += `&features=${params.features.join(';')}`
    }

    if (params.tags) {
      queryParams += `&tags=${params.tags.join(';')}`
    }

    return api.get(`/products${queryParams}`)
  },

  getAllProductsToStore(page, term, perPage = null) {
    let params = `?page=${page}&term=${term}`;
    if (perPage) {
      params += `&perPage=${perPage}`;
    }
    return api.get(`/products/store${params}`)
  },

  createProduct(data) {
    return api.post('/products', data)
  },

  updateProduct(id, data) {
    return api.put('/products/' + id, data)
  },

  removeProduct(id) {
    return api.delete('/products/' + id)
  },

  getProduct(id) {
    return api.get('/products/' + id)
  },

  // store products
  getProductStore(id) {
    return api.get('/stores/products/' + id)
  },

  updateStoreProduct(id, data) {
    return api.put('/products-store/' + id, data)
  },

  createStoreProduct(data) {
    return api.post('/products-store', data)
  },

  removeStoreProduct(id) {
    return api.delete('/products-store/' + id)
  },

  getStoreBrands() {
    return api.get('/products-store/brands')
  },

  getStoreCategories() {
    return api.get('/products-store/categories')
  },

  // variations
  getVariations(id) {
    let params = '';
    if (id) {
      params = `?productId=${id}`;
    }

    return api.get(`/products/variations${params}`)
  },

  // categories
  getCategories() {
    return api.get('/products/categories')
  },
  createCategory(data) {
    return api.post('/products/categories', data)
  },
  updateCategory(data) {
    return api.put(`/products/categories/${data._id}`, data)
  },
  removeCategory(data) {
    return api.delete(`/products/categories/${data._id}`)
  },

  // brands
  getBrands() {
    return api.get('/products/brands')
  },
  createBrand(data) {
    return api.post('/products/brands', data)
  },
  updateBrand(data) {
    return api.put(`/products/brands/${data._id}`, data)
  },
  removeBrand(data) {
    return api.delete(`/products/brands/${data._id}`)
  },

  // features
  getFeatures() {
    return api.get('/products/features')
  },
  createFeature(data) {
    return api.post('/products/features', data)
  },
  updateFeature(data) {
    return api.put(`/products/features/${data._id}`, data)
  },
  removeFeature(data) {
    return api.delete(`/products/features/${data._id}`)
  },

  // tags
  getTags(query = '') {
    return api.get(`/products/tags${query}`)
  },
  createTag(data) {
    return api.post('/products/tags', data)
  },
  updateTag(data) {
    return api.put(`/products/tags/${data._id}`, data)
  },
  removeTag(data) {
    return api.delete(`/products/tags/${data._id}?index=${data.index}`)
  },

  uploadImage(id, formData) {
    return api.put('/products/image/' + id, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  removeImage(id, index) {
    return api.delete(`/products/image/${id}/${index}`);
  },

  exportProducts(params) {
    let queryParams = '?export=true';

    if (params.term) {
      queryParams += `&term=${params.term}`;
    }

    if (params.brands) {
      queryParams += `&brands=${params.brands.join(';')}`
    }

    if (params.categories) {
      queryParams += `&categories=${params.categories.join(';')}`
    }

    if (params.features) {
      queryParams += `&features=${params.features.join(';')}`
    }

    if (params.tags) {
      queryParams += `&tags=${params.tags.join(';')}`
    }

    return api({
      url: `/products${queryParams}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  exportStoreProducts(params) {
    let queryParams = '?export=true';

    if (params.term) {
      queryParams += `&term=${params.term}`;
    }

    return api({
      url: `/stores/products${queryParams}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  uploadProductFile(file, options) {
    const formData = new FormData();
    formData.append('file', file);

    return api.post('/products-store/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'update-price': options.updatePrice ? 'T' : 'F',
        'update-stock': options.updateStock ? 'T' : 'F',
      }
    })
  }
}